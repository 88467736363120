<template>
    <div class="siteMenu" :class="{active: showSiteMenu}">
        <div class="bg" @click="showHideMenu(false)"></div>
        <div class="hideMenuIco" @click="showHideMenu(true)" v-if="!showSiteMenu.value">
            <IconIcMenu />
        </div>
        <div class="contentBox" :class="{w1: ['amif', 'rea', 'bea', 'classyr'].includes(runtimeConfig?.public.engine)}">
            <div class="head">
                <div class="menuIco" @click="showHideMenu(false)">
                    <IconIcMenu />
                </div>
                <div class="logo">
                    <IconLogo1 v-if="runtimeConfig?.public.engine == 'real'"  />
                    <img v-else :src="useAssetsImage(`${runtimeConfig?.public.engine}/logo1.svg`)" />
                </div>
            </div>
            <template v-if="userinforef == undefined || userinforef?.visitor_token">
                <NuxtLink class="signIn" to="/login">Log in</NuxtLink>
                <NuxtLink class="signUp" to="/register">Sign up</NuxtLink>
                <div class="line">
                </div>
                <div class="moreLink">
                    <li v-if="['bw', 'am', 'be', 'el', 'ti', 'di', 'amiu', 'cha', 'toa', 'elega', 'att', 'class', 'ho'].includes(runtimeConfig?.public.engine)">
                        <NuxtLink to="/about-us">About Us</NuxtLink>
                    </li>
                    <li v-if="['bw'].includes(runtimeConfig?.public.engine)">
                        <a href="https://www.pinterest.com/bwfinder/" target="_blank">Pinterest</a>
                    </li>
                    <li v-if="['be'].includes(runtimeConfig?.public.engine)">
                        <a href="https://www.pinterest.com/Realbeautypics/" target="_blank">Pinterest</a>
                    </li>
                    <li v-if="['ti'].includes(runtimeConfig?.public.engine)">
                        <a href="https://www.pinterest.com/jeannazou/" target="_blank">Pinterest</a>
                    </li>
                    <li v-if="['elega'].includes(runtimeConfig?.public.engine)">
                        <a href="https://www.facebook.com/profile.php?id=61558860905616" target="_blank">FaceBook</a>
                    </li>
                    <li v-if="['ho'].includes(runtimeConfig?.public.engine)">
                        <a href="https://www.instagram.com/100honest8" target="_blank">Instagram</a>
                    </li>
                    <li>
                        <NuxtLink to="/setting/terms-of-service">Terms of Service</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink to="/setting/privacy-policy">Privacy Policy</NuxtLink>
                    </li>
                </div>
                <div class="footer" v-if="['att'].includes(runtimeConfig?.public.engine)">Copyright © 2024 {{ appName }}.com. <br>All Rights Reserved.</div>
            </template>
            <div class="tabUserType" v-else-if="hasMultiType || isTestEnv">
                <div class="item" @click="switchUserType(1)" :class="{ selected: currentUserType == 1 }">
                    <p>SELFIE <br> COMMUNITY</p>
                    <div class="radio">
                        <IconCheck v-if="currentUserType == 1" />
                    </div>
                </div>
                <div class="item" @click="switchUserType(0)" :class="{ selected: currentUserType == 0 }">
                    <p>NON-SELFIE COMMUNITY</p>
                    <div class="radio">
                        <IconCheck v-if="currentUserType == 0" />
                    </div>
                </div>
            </div>
        </div>
        <div class="noSelfieDialog" v-if="showNoSelfieDialog">
            <div class="contentWarp">
                <h4>Join the Selfie Community!</h4>
                <p>To ensure all users in this community are real, please take a selfie. </p>
                <div class="btn" @click="gotoUploadSelfie">TAKE A SELFIE</div>
            </div>
        </div>
        <div class="pageLoading" v-if="switchLoading"></div>
    </div>
</template>

<script setup>
const router = useRouter();
const { appName, domain } = useRuntimeConfig().public;
const showSiteMenu = ref(false)
const runtimeConfig = useRuntimeConfig();
const { useAssetsImage } = useAssets()
const userinforef = useState("userinforef");
const isTestEnv = ref(false)
const hasMultiType = useCookie("hasMultiType");
const currentUserType = useCookie("currentUserType");
const showNoSelfieDialog = ref(false)
const switchLoading = ref(false)
const switchUserTypeNeedRefresh = useState("switchUserTypeNeedRefresh");
const showHideMenu = (val) => {
    showSiteMenu.value = val
}
const goIndex = () => {
    showHideMenu(false)
    router.push({path: '/', query: {notAutoJump: 1}})
}
const gotoUploadSelfie = () => {
    showNoSelfieDialog.value = false
    switchUserTypeNeedRefresh.value = true
    showHideMenu(false)
    nextTick(() => {
        let selectCamera = document.getElementById('navBarSelectVideoStream'),
            selectFile = document.getElementById('navBarSelectFileInput')
        if (selectCamera && selectCamera.click) {
            selectCamera.click()
        } else if (selectFile && selectFile.click) {
            selectFile.click()
        }
    })
}
const switchUserType = (value) => {
    if (currentUserType.value == value) return
    switchLoading.value = true
    getPostList({
        type: "my",
        page: 1,
        isSelf: 1,
        callback: (res) => {
            let noPost = true
            if (res && res.data && res.data.length > 0) {
                noPost = false
            }
            let userinfo = useCookie("userinfo");
            if (value == 1 && userinfo && (parseInt(userinfo.value.hasSelfie) == 0 || noPost)) {
                switchLoading.value = false
                showNoSelfieDialog.value = true
                return
            }
            currentUserType.value = value
            window.location.reload()
        }
    })
}
onMounted(()=>{
    switchLoading.value = false
    isTestEnv.value = window.location.origin == "https://www.1000realdev.net" ? true : false
    switchUserTypeNeedRefresh.value = false
    showNoSelfieDialog.value = false
    let userinfo = useCookie("userinfo");
    userinforef.value = userinfo.value;
    if (userinfo.value && currentUserType.value == undefined) {
        currentUserType.value = parseInt(userinfo.value.hasSelfie) == 1 ? 1 : 0;
    }
    showHideMenu(false)
})
onUnmounted(() => {
    showHideMenu(false)
})
</script>
<style scoped lang="scss">
.siteMenu {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 0;
    height: 100%;
    .bg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.60);
        position: absolute;
    }
    .contentBox {
        position: absolute;
        width: 28.4rem;
        left: -28.4rem;
        background-color: #2E2E2E;
        transition: all 0.2s;
        height: 100%;
        z-index: 3;
        padding: 1.6rem;
        box-sizing: border-box;
    }
    .contentBox.w1 {
        width: 34rem;
        left: -34rem;
    }
    &.active {
        width: 100%;
    }
    &.active .contentBox {
        left: 0;
    }
    .hideMenuIco {
        width: 2.4rem;
        height: 2.4rem;
        left: calc(100% + 1.6rem);
        top: 1.6rem;
        color: #ffffff;
        position: absolute;
        z-index: 4;
    }
    .head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 0.8rem;
        .logo {
            margin-left: 1.6rem;
            color: RGBA(246, 113, 40, 1);
            width: 15.8rem;
            height: 2.6rem;
            svg{
                color:#ffffff;
            }
        }
        .menuIco {
            width: 2.4rem;
            height: 2.4rem;
            color: $mainftColor;
        }
    }
    .signIn,
    .signUp {
        font-family: Poppins-Medium;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.6rem;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.8rem;
        border-radius: 2rem;
        margin-top: 2.4rem;
    }
    .signIn {
        background-color: $mainftColor;
        color: $mainColor;
    }
    .signUp {
        color: $mainftColor;
        border: 0.1rem solid $mainftColor;
    }
    .line {
        height: 0.1rem;
        background-color: rgba(70, 70, 70, 1);
        margin-top: 3.2rem;
    }
    .moreLink {
        margin-top: 0.8rem;
        list-style: none;
    }
    .moreLink li {
        text-align: center;
        margin-top: 2.4rem
    }
    .moreLink li a {
        @extend .publicFont3;
        font-size: 1.4rem;
        line-height: 2.6rem;
        color: $mainftColor;
    }
    .footer{
        position: absolute;
        bottom: 2rem;
        text-align: center;
        left: 4rem;
    }
}
.tabUserType {
    margin: 0;
    .item {
        border: 0.1rem solid #F7F7F7;
        border-radius: 1.2rem;
        margin-top: 1.6rem;
        padding: 1.2rem 5.8rem 1.2rem 2rem;
        position: relative;
        p {
            font-size: 1.6rem;
            padding: 0;
            margin: 0;
            line-height: 2.6rem;
            @extend .publicFont3;
            color: #fff;
        }
        .radio {
            width: 1.8rem;
            height: 1.8rem;
            border: 0.1rem solid #838383;
            border-radius: 1rem;
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .item.selected {
        background-color: #F7F7F7;
        p {
            color: #0F0F0F;
        }
        .radio {
            border: 0.1rem solid #0F0F0F;
            background-color: #0f0f0f;
        }
    }
}
.noSelfieDialog {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.60);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .contentWarp {
        background-color: #ffffff;
        border-radius: 0.8rem;
        max-width: calc(100vw - 4rem);
        width: 100%;
        padding: 3.2rem 2.4rem 2.4rem;
        box-sizing: border-box;
        h4 {
            text-align: center;
            font-size: 1.5rem;
            line-height: 2.6rem;
            @extend .publicFont4;
            color: $mainColor;
            margin: 0;
        }
        p {
            font-size: 1.5rem;
            line-height: 2.6rem;
            margin: 1.2rem 0 0 0;
            padding: 0;
            color: $mainColor;
        }
    }
    .btn {
        width: 25rem;
        height: 4rem;
        line-height: 4rem;
        background-color: $mainColor;
        color: $mainftColor;
        border-radius: 2rem;
        text-align: center;
        @extend .publicFont3;
        margin: 2.4rem auto 0;
        font-size: 1.6rem;
    }
}
.pageLoading {
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
.pageLoading:after {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    content: '';
    width: 100px;
    height: 100px;
    border-radius: 100px;
    -webkit-animation: loadingPage 1s infinite ease-in-out;
    animation: loadingPage 1s infinite ease-in-out;
    background: #fff;
}
@-webkit-keyframes loadingPage {
    0% {
        -webkit-transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes loadingPage {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}
</style>