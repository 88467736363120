<template>
    <div class="bgColor">
        <div class="publicBody">
            <div class="logo">
                <img src="~/assets/classy/logo3.svg" />
            </div>
            <div class="contentBox">
                <div class="left">
                    <div class="homeVideo"></div>
                </div>
                <div class="right">
                    <div class="bigtitle">
                        Elevate Your Photography with ClassySelfies.com
                    </div>
                    <div class="title">
                        Welcome to ClassySelfies.com! Here, you can share your sophisticated selfies and receive genuine ratings from fellow members. Likewise, feel free to rate others' photos based on what resonates with you. Join our community today and let the world see your unique style!
                    </div>
                    <!-- <div class="title">
                        Explore stunning monochrome photos, engage in thoughtful rating, and elevate your photo game. Whether a photography enthusiast or aspiring model, ClassySelfies.com is your refined platform for self-expression. Join us and elevate your elegance today!
                    </div> -->
                    <div class="mobiledevice"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup></script>

<style scoped lang="scss">
.publicBody {
    width: 1148px;
    margin: auto;
    min-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 84px;
    .logo {
        margin-top: 50px;
        width: 305px;
        height: 31px;
        i {
            color: rgb(18, 18, 18);
            width: 305px;
            height: 31px;
        }
    }
    .contentBox {
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        .left .homeVideo {
            width: 350px;
            height: 758px;
            flex-shrink: 0;
            position: relative;
            overflow: hidden;
            border-radius: 40px;
            object-position: top;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url('~/assets/classy/pchomevideoposter.png');
        }
        .right {
            margin-left: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 460px;
            .title {
                margin-top: 24px;
                font-size: 32px;
                font-family: "Anton-Regular";
                font-weight: 400;
                color: $mainColor;
                line-height: 48px;
                letter-spacing: 1px;
            }
            .bigtitle {
                font-size: 46px;
                font-family: "Anton-Regular";
                font-weight: 400;
                color: #f35600;
                line-height: 72px;
                letter-spacing: 1px;
            }
            .mobiledevice {
                width: 375px;
                height: 166px;
                background: url("@/assets/img/mobile_device.png");
                background-size: 100% 100%;
                overflow: hidden;
                clear: both;
                margin-top: 8px;
            }
            .tip {
                font-family: Poppins-Light;
                font-size: 20px;
                color: #161616;
                line-height: 32px;
            }
        }
    }
}
.bgColor{
    height: 100%;
    width: 100%;
    background-color: #fff;
}
</style>
